import * as React from 'react';
import Layout from '../components/layout';
import VideoBG from '../images/not-found-video.mp4';
import Link from '../components/link';

export default function NotFoundPage() {
  return (
    <Layout noFooter>
      <video
        src={VideoBG}
        className="pointer-events-none fixed inset-0 -z-10 h-full w-full object-cover"
        preload="auto"
        autoPlay
        muted
        loop
        playsinline
        type="video/mp4"
      />

      <div className="absolute inset-0 -z-10 flex h-full w-full items-center justify-center object-cover">
        <div className="text-center">
          <h1 className="mt-20 inline bg-gradient-to-r from-cyan-500 via-sky-500 to-indigo-400 bg-clip-text text-center text-9xl font-bold text-transparent">
            404
          </h1>
          <div className="mt-4 max-w-3xl">
            <p className="text-4xl font-bold text-gray-200">You must be lost</p>
            <p className="mx-auto mt-4 max-w-lg text-2xl text-gray-200">
              Looks like you’ve followed a broken link or entered a URL that doesn’t exist on this
              website.
            </p>

            <Link
              className="mt-8 inline-block transform rounded-lg bg-gradient-to-r from-cyan-500 via-sky-500 to-indigo-400 p-[2px] hover:scale-[1.02] md:text-lg"
              to="/"
            >
              <div className="flex items-center justify-center whitespace-nowrap rounded-md bg-black px-5 py-3 font-semibold leading-none">
                <span className="inline bg-gradient-to-r from-cyan-500 via-sky-500 to-indigo-400 bg-clip-text text-transparent">
                  Start from the beginning
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
